$blue: #346EB5;
$gray-dark: #737B7D;
$black-light: #373F41;
$green: #4BB543;
$table-gray: #707683;
$image-margin: 48px;
$image-offset: 20px;
$image-offset-small: 10px;
$image-wrapper-padding: 20px;
$image-wrapper-padding-small: 10px;
$image-border-radius: 10px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 752px,
  lg: 992px,
  xl: 1140px,
  xxl: 1172px
);

@import "node_modules/bootstrap/scss/bootstrap";
@import "./containers/DashboardPage/style.scss";

.btn {
    padding: .7rem 2rem;
}

.form-control {
    padding: 1rem 1rem;
    height: 50px;

    @include media-breakpoint-down(sm) {
        font-size: 12px;
    }
}

body {
    font-family: 'Comfortaa', sans-serif;
    color: $black-light;
    letter-spacing: .3px;
}

h1, h2, h3, h4, h5, h6, .landing-statistics-text {
    font-family: 'Playfair Display', serif;
}

.site-header {
    &-transparent {
        // Header nav styling
        background: none;
    }

    &-variant-blue {
        .navbar-light .navbar-nav .nav-link {
            color: #202020;

            &:hover, &:focus {
                color: $blue;
            }
        }
    }

    .navbar {
        padding: 0.7rem 1rem;
    }

    .nav-link {
        padding: .7rem 1rem !important;

        span {
            position: relative;
            display: inline-block;
        }
    }

    .nav-link.active {
        color: $blue !important;

        span::after {
            display: block;
            content: "";
            height: 3px;
            width: 100%;
            position: absolute;
            top: 100%;
            left: 50%;
            background: $blue;
            transform: translate(-50%, 0);
            border-radius: 10px;
        }
    }

    @include media-breakpoint-down(sm) {
        .site-logo {
            height: 55px;
        }

        .navbar {
            padding: 10px 0;
        }

        .nav-link {
            padding: .5rem 0 !important;
        }
    }
}

.site-logo {
    height: 88px;
}

.hero {
    min-height: 384px;
    background-size: cover;
    background-position: center;
    color: #fff;

    &-content-wrapper {
        flex: 1 1 auto;
        padding-top: 144px;
        padding-bottom: 246px;
    }

    &:not(.with-header) {
        .hero-content-wrapper {
            padding-top: 246px;

            @include media-breakpoint-down(xl) {
                padding-top: 210px;
            }

            @include media-breakpoint-down(sm) {
                padding-top: 222px;
            }
        }
    }

    &-text {
        text-align: center;
        max-width: 986px;
        letter-spacing: 0.3px;
    }

    h2 {
        margin-bottom: 1rem;
        font-size: 40px;
        line-height: 1.2;
        font-weight: bold;
    }

    p {
        font-weight: bold;
    }

    @include media-breakpoint-down(xl) {
        &-text {
            max-width: 640px;
        }

        &-content-wrapper {
            padding-top: 108px;
            padding-bottom: 210px;
        }
    }

    @include media-breakpoint-down(sm) {
        h2 {
            font-size: 24px;
        }

        p {
            font-size: 12px;
        }

        &-text span {
            display: block;
        }

        &-content-wrapper {
            padding-top: 154px;
            padding-bottom: 222px;
        }
    }
}

.site-footer {
    background-color: $blue;
    padding: 35px 0;
    color: #fff;

    .site-logo {
        height: 145px;
    }

    .footer-copyright {
        font-size: 12px;
    }
}

.footer-nav {
    a, a:hover, a:focus {
        color: #fff;
    }

    a:hover {
        text-decoration: underline;
    }
}

.invest-reason-card {
    border: none;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.08);

    .card-body {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 28px;
    }

    &-icon {
        flex: 0 0 80px;
        max-height: 80px;
        max-width: 80px;
        object-fit: contain;
        object-position: center;
    }

    &-content {
        align-self: flex-start;

        h3 {
            font-family: inherit;
            font-size: 24px;
            margin-bottom: 14px;
        }
    }

    @include media-breakpoint-down(md) {
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    @include media-breakpoint-down(sm) {
        &-content {
            h3 {
                font-size: 16px;
            }

            p {
                font-size: 12px;
            }
        }

        .card-body {
            padding: 28px 22px;
        }

        &-icon {
            flex-basis: 48px;
            width: 52px;
            height: 52px;
        }
    }
}

.landing-block {
    &-image-wrapper {
        margin-left: $image-margin;
        margin-right: 0;
        order: 2;
    }

    &-content-wrapper {
        order: 1;

        h3 {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 16px;
        }

        .landing-block-text {
            color: $gray-dark;
            font-size: 16px;
            margin-bottom: 24px;
        }
    }

    &.content-side-right {
        .landing-block-image-wrapper {
            margin-right: $image-margin;
            margin-left: 0;
            order: 1;

            &::before {
                transform: translate($image-offset, $image-offset);

                @include media-breakpoint-down(sm) {
                    transform: translate($image-offset-small, $image-offset-small);
                }
            }
    
            img {
                transform: translate(0, 0);
            }
        }

        .landing-block-content-wrapper {
            order: 2;
        }
    }

    &-image-wrapper {
        position: relative;
        padding: $image-wrapper-padding;
        height: 320px;
        max-height: 320px;
        flex: 0 0 538px;

        @include media-breakpoint-down(sm) {
            padding: $image-wrapper-padding-small;
        }

        &::before, img {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% - #{$image-wrapper-padding});
            height: calc(100% - #{$image-wrapper-padding});
            border-radius: $image-border-radius;

            @include media-breakpoint-down(sm) {
                width: calc(100% - #{$image-wrapper-padding-small});
                height: calc(100% - #{$image-wrapper-padding-small});
            }
        }

        &::before {
            display: block;
            content: "";
            border: 2px solid $blue;
            transform: translate(0, $image-offset);

            @include media-breakpoint-down(sm) {
                transform: translate(0, $image-offset-small);
            }
        }

        img {
            max-height: 100%;
            object-fit: cover;
            object-position: center;
            transform: translate($image-offset, 0);

            @include media-breakpoint-down(sm) {
                transform: translate($image-offset-small, 0);
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding-top: 32px !important;
        padding-bottom: 32px !important;

        &-image-wrapper {
            margin: 0 !important;
            width: 100%;
            margin-bottom: 1.5rem !important;
            order: 1;
            height: 420px;
            max-height: 420px;
        }

        &-content-wrapper {
            order: 2;
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: 20px !important;
        padding-bottom: 20px !important;

        &-image-wrapper {
            height: 210px;
            flex-basis: 210px;
        }

        &-content-wrapper {
            h3 {
                font-size: 24px;
            }

            .landing-block-text {
                font-size: 12px;
            }
        }

        .btn {
            font-size: 12px;
        }
    }
}

.landing-statistics {
    padding: 3rem 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    &-wrapper {
        background-color: rgb(239, 243, 249);
    }

    &-item {
        flex: 1 1 auto;
        padding: 22px 16px;
    }

    &-spacer {
        display: flex;
        flex: 0 0 1px;
        background: #737B7D;
    }

    &-text {
        font-size: 40px;
        line-height: 1.2;
        font-weight: bold;
    }

    &-description {
        color: $gray-dark;
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;
        padding: 0;

        &-spacer {
            max-width: 128px;
            width: 100%;
        }
    }
}

.email-terms {
    &, a {
        color: $gray-dark;
    }
}

.team-members-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &-item {
        flex: 0 0 calc(25% - 20px);
        text-align: center;
        margin-bottom: 2rem;

        img {
            width: 100%;
            height: 250px;
            object-fit: cover;
            object-position: top center;
            border-radius: 10px;
            aspect-ratio: 1/1;
            margin-bottom: 20px;
        }
    }

    .team-member-name {
        font-weight: bold;
        font-size: 18px;
    }

    p {
        font-size: 16px;
    }

    @include media-breakpoint-down(md) {
        &-item {
            flex: 0 0 calc(50% - 15px);
        }
    }

    @include media-breakpoint-down(sm) {
        &-item {
            img {
                height: 158px;
                margin-bottom: 14px;
            }
        }

        .team-member-name {
            font-size: 16px;
        }

        p {
            font-size: 12px;
        }
    }
}

.contacts-map-wrapper {
    position: relative;
    height: 320px;

    &::before, img {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - #{$image-wrapper-padding});
        height: calc(100% - #{$image-wrapper-padding});
        border-radius: $image-border-radius;

        @include media-breakpoint-down(sm) {
            width: calc(100% - #{$image-wrapper-padding-small});
            height: calc(100% - #{$image-wrapper-padding-small});
        }
    }

    &::before {
        display: block;
        content: "";
        border: 2px solid $blue;
        transform: translate(0, $image-offset);

        @include media-breakpoint-down(sm) {
            transform: translate(0, $image-offset-small);
        }
    }

    img {
        object-fit: cover;
        object-position: center;
        transform: translate($image-offset, 0);
        max-height: 100%;

        @include media-breakpoint-down(sm) {
            transform: translate($image-offset-small, 0);
        }
    }
}

.product-card {
    padding: 40px;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.08);

    &-price {
        strong {
            font-size: 48px;
            font-family: 'Playfair Display', serif;
        }
    }

    &-title {
        margin: 14px 0;
        font-size: 22px;
        font-family: 'Comfortaa', sans-serif;
    }

    &-description, &-features-list, &-price span {
        font-size: 14px;
        color: $gray-dark;
    }

    &-features-list {
        padding: 0;
        margin-bottom: 80px;
        list-style: none;

        li {
            padding-left: 40px;
            background-image: url('./img/check.svg');
            background-repeat: no-repeat;
            background-size: auto 18px;
            background-position: 0px 0px;
            line-height: 18px;
            margin-bottom: 18px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    button {
        margin-top: auto;
    }

    .line {
        margin: 2.5rem 0;
        border-bottom: 1px solid rgba($gray-dark, .2);
    }

    @include media-breakpoint-down(sm) {
        padding: 24px;

        &-title {
            font-size: 18px;
        }

        &-description {
            font-size: 12px;
        }

        &-features-list {
            li {
                font-size: 12px;
                margin-bottom: 24px;
            }
        }

        .btn {
            font-size: 12px;
        }
    }
}

.products-slider {
    padding-bottom: 46px;
    padding-top: 6px;

    .swiper-slide {
        padding: 5px;
        height: auto;
    }

    .swiper-pagination-bullet-active {
        background: $blue;
    }
}

.email-block {
    max-width: 635px;
    margin-left: auto;
    margin-right: auto;

    &-wrapper {
        background: #FCFCFC;
        padding: 60px 0;

        @include media-breakpoint-down(sm) {
            background: none;
        }
    }

    .form-control, .btn {
        padding: 15px 30px;
        font-size: 16px;
        line-height: 1;
    }

    .form-control {
        background: #F4F5F4;
        border: 1px solid #F4F5F4;
        border-radius: 4px !important;
        height: auto;
        padding-left: 15px;
    }

    .btn {
        border-radius: 4px !important;
    }

    .email-terms {
        font-size: 12px;
        line-height: 2;
        color: $gray-dark;
        letter-spacing: .2px;
    }

    @include media-breakpoint-down(md) {
        max-width: 100%;
    }

    @include media-breakpoint-down(sm) {
        .form-control, .btn {
            font-size: 12px;
        }

        .btn {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.contact-info {
    &-block {
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        background: #fff;
        margin-top: -150px;

        .socials-nav a:first-child {
            padding-left: 0;
        }

        h2 {
            font-size: 40px;
        }

        .socials-nav {
            margin-top: 20px;
        }

        @include media-breakpoint-down(md) {
            .col-reach-us-out {
                margin-bottom: 2rem;
            }
        }

        @include media-breakpoint-down(sm) {
            .btn {
                font-size: 12px;
            }
        }
    }

    &-list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding-left: 30px;
            background-position: top left;
            background-repeat: no-repeat;
            background-size: auto 18px;
            margin-bottom: 1.5rem;
            color: $gray-dark;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .address {
            background-image: url('./img/location.svg');
        }

        .phone {
            background-image: url('./img/phone.svg');
        }

        .mail {
            background-image: url('./img/mail.svg');
        }
    }

    @include media-breakpoint-down(sm) {
        &-block {
            padding: 30px 30px !important;
            font-size: 12px;

            h2 {
                font-size: 24px;
            }
        }
    }
}

.faqs-container {
    max-width: 730px;

    .card {
        border: 2px solid #EBECED;
        border-left: none;
        border-right: none;
        padding: 1.5rem 0;
        font-size: 1.5rem;

        &-header, &-body {
            padding-left: 0;
            padding-right: 30px;
            color: #373F41;
        }
    }

    .card-header {
        $icon-translate: translate(0, -50%);
        border: none;
        background: none;
        transition: color .2s;
        position: relative;

        &.active {
            color: $blue;

            &::after {
                transform: $icon-translate rotate(45deg);
            }
        }

        &::after {
            $size: 20px;
            display: block;
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            width: $size;
            height: $size;
            background-image: url('./img/plus.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto $size;
            transform: $icon-translate;
            transition: transform .2s;
        }
    }

    .card-body {
        color: $gray-dark;
        font-size: 1rem;
    }

    .section-header {
        font-size: 40px;
    }

    @include media-breakpoint-down(sm) {
        .section-header {
            font-size: 24px;
            margin-bottom: 28px !important;
        }

        .card {
            font-size: 12px;
            padding: 12px 0;
        }

        .card-body {
            padding-top: 10px;
        }

        .card-header::after {
            width: 14px;
            height: 14px;
            background-size: auto 14px;
        }
    }
}

.dashboard-header {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    z-index: 999;

    &-navbar {
        height: 80px;

        .site-logo, .navbar-brand {
            height: 100%;
            max-height: 100%;
        }
    }
}

.notifications-button, .user-avatar, .toggle-sidebar-button {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    position: relative;

    img {
        max-height: 100%;
        height: 100%;
        width: auto;
    }
}

.notifications-button {
    padding-left: 12px !important;
    padding-right: 12px !important;

    .notifications-count {
        $size: 20px;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background: #FF6760;
        color: #fff;
        width: $size;
        height: $size;
        line-height: $size;
        font-size: 10px;
        border: 2px solid #F6F8FB;
        border-radius: 50%;
        text-align: center;
        font-weight: bold;
    }
}

.user-avatar {
    img {
        border-radius: 50%;
        border: 2px solid #F6F8FB;
    }

    &-wrapper {
        position: relative;
        display: inline-block;
    }
    
    &-edit-btn {
        $size: 20px;
        position: absolute;
        top: 0;
        right: 0;
        width: $size;
        height: $size;
        background: $blue;
        border: none;
        outline: none !important;
        border-radius: 50%;
        padding: 0;

        svg {
            margin-top: -4px;
        }
    }
}

.toggle-sidebar-button, #dashboard-sidebar .nav .nav-link-icon {
    padding-left: 2rem;
    padding-right: 2rem;

    img, svg {
        width: 18px;
        height: auto;
    }
}

.dashboard {
    &-page-wrapper {
        .section-title {
            font-size: 40px;
            margin-bottom: 2rem;

            @include media-breakpoint-down(md) {
                font-size: 32px;
            }
        }
    }

    &-actions {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        margin-bottom: 40px;

        .dashboard-action {
            margin-right: 20px;

            &-search {
                flex: 1 1 auto;

                input {
                    font-size: 16px !important;
                }
            }

            &-sort {
                .dropdown {
                    &-toggle::after {
                        margin-left: 20px;
                    }
                }
            }

            button {
                padding-left: 15px;
                padding-right: 15px;
            }

            &:last-child {
                margin-right: 0;
            }

            @include media-breakpoint-down(lg) {
                &-search {
                    flex: 1 0 100%;
                    margin-bottom: 20px;
                }

                &-sort {
                    flex: 1 1 auto;

                    button {
                        width: 100%;
                        text-align: left;
                        position: relative;
                        padding-right: 30px;

                        &::after {
                            position: absolute;
                            right: 20px;
                            top: calc(50% - 2px);
                        }
                    }
                }
            }

            @include media-breakpoint-down(md) {
                button {
                    font-size: 14px;
                }
            }
        }
    }
}

#dashboard-sidebar {
    .nav {
        margin-top: 1.5rem;

        .nav-link {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: .5rem;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            color: $gray-dark;
            transition: color .2s;

            &-title {
                width: 0;
                overflow: hidden;
                transition: width .3s;
                white-space: nowrap;

                span {
                    padding-right: 1.5rem;
                }
            }

            svg * {
                transition: fill .2s, stroke .2s;
            }

            &:hover, &.active {
                color: $blue;

                svg * {
                    fill: $blue;
                    stroke: $blue;
                }
            }
        }

        .sidebar-nav-link-dashboard {
            &:hover, &.active {
                svg * {
                    fill: none;
                    stroke: $blue;
                }
            }
        }
    }

    &.active .nav-link-title {
        width: 200px;
    }
}

.no-items-card {
    max-width: 730px;
    margin: 200px auto;
    border: 1px dashed #A0A4A8;
    border-radius: 16px;
    padding: 60px;

    h3 {
        color: $blue;
        font-family: inherit;
    }

    img {
        width: 80px;
        height: 80px;
        object-fit: contain;
        object-position: center;
    }
}

.profile-card {
    .card-body {
        padding: 32px;
    }

    &-title {
        font-family: inherit;
        color: $blue;
        margin: 15px 0;
        font-size: 24px;
        text-align: center;
    }

    .profile-name {
        text-align: left;
        margin-bottom: 4px;
    }

    .profile-bio {
        font-size: 14px;
        color: $gray-dark;
    }

    hr {
        margin: 32px 0;
    }

    &-contact-info {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid #D5D5D5;
    }

    .contact-info-list li {
        padding-left: 32px;
    }

    &.card-user-profile {
        .card-body {
            display: flex;
            flex-direction: column;
        }

        @include media-breakpoint-between(md, lg) {
            .card-body {
                flex-direction: row;
            }

            .profile-info, .profile-card-contact-info {
                flex: 0 0 50%;
            }

            .profile-card-contact-info {
                margin: 0;
                padding: 45px 50px;
                border: none;
                border-left: 1px solid #D5D5D5;
            }

            .profile-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &.card-balance {
        .profile-card-title {
            margin-bottom: 4px;
        }

        .current-balance {
            color: #373f41;
            margin-bottom: 40px;
        }
    }

    &.card-membership {
        .membership-plan-switch {
            margin-top: 30px;
            width: 100%;

            .btn {
                display: block;
                width: 100%;
            }

            .nav-item {
                padding: 0 10px;
                flex: 1 1 auto;
                margin-bottom: 15px;
            }
        }

        .membership-plna-terms {
            color: $gray-dark;
            font-size: 12px;
            text-align: center;
        }

        .membership-plan-price {
            text-align: center;
            color: $green;
        }
    }
}

.profile-buttons-nav {
    .btn {
        font-size: 14px;
        padding: 15px 20px;

        &:not(.active) {
            background: none;
            color: $blue;
        }

        &.active {
            background: $blue !important;
        }
    }
}

.profile-tabs-nav {
    .btn {
        margin-right: 15px;
    }
}

.login-modal {
    .modal-footer, .modal-header {
        background: #EFF3F9;
    }

    .social-login-buttons {
        .btn {
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            padding: .8rem 1.5rem;

            img {
                height: 24px;
                width: auto;
            }
        }
    }

    .site-logo {
        height: 145px;
        width: auto;

        @include media-breakpoint-down(sm) {
            height: 102px;
        }
    }

    .modal-dialog {
        @include media-breakpoint-up(sm) {
            max-width: 540px;
        }
    }

    .sign-up-steps {
        margin-bottom: 35px;

        .step-numbers, .step-names {
            display: flex;
            flex-flow: row nowrap;
        }

        .step-numbers {
            align-items: center;
            padding-left: 40px;
            padding-right: 38px;

            .step {
                padding: 10px;
            }

            .step[data-active="true"] {
                padding: 6px;

                img {
                    height: 38px;
                }
            }

            img {
                height: 32px;
                width: auto;
            }
        }

        .step-line {
            flex: 1 1 auto;
            height: 1px;
            background: #D9D9D9;

            &[data-passed="true"] {
                background: $blue;
            }
        }

        .step-names {
            justify-content: space-between;
            margin-bottom: 10px;

            .step-name {
                font-size: 11px;
                width: 130px;
                text-align: center;
                color: #737B7D;

                &[data-active="true"] { color: $blue; }
                &[data-passed="true"] { color: #373F41; }
            }
        }
    }
}

.landing-location-block-container {
    p {
        color: $gray-dark;
    }
}

.contacts-page-hero {
    min-height: 700px;

    @include media-breakpoint-down(xl) {
        min-height: 500px;
    }
}

.section-header {
    font-size: 40px;

    @include media-breakpoint-down(sm) {
        font-size: 24px;
    }
}

table, .table {
    background: #fff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.08);
    color: $table-gray;
    font-size: 14px;
    
    thead {
        background: $blue;
        color: #fff;
    }

    td, th {
        padding: 20px 16px;
    }

    th {
        padding-top: 18px;
        padding-bottom: 18px;
    }

    td {
        vertical-align: middle;
    }
}

.td-nowrap {
    white-space: nowrap;
}

.td-keywords {
    color: $blue;

    span {
        margin-right: 10px;
    }
}

.table {
    &-scroll-wrapper {
        margin-bottom: 20px;

        &::-webkit-scrollbar {
            height: 10px;
        }
        
        &::-webkit-scrollbar-track {
            background: #EBECED;
            border-radius: 8px;
        }
        
        &::-webkit-scrollbar-thumb {
            background: #CFCFCF;
            border-radius: 8px;
        }
    }

    .alert {
        margin: 0;
        display: inline-block;
        padding: 2px 6px;
    }
}

.dashboard-table {
    min-width: 740px;
}

.profile-tab .table {
    min-width: 1110px;
}

.pagination {
    $button-spacing: 4px;

    &-items {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-flow: row wrap;

        svg {
            max-height: 28px;
            max-width: 28px;
        }
    }

    .previous, .next {
        a {
            padding: 0 !important;
        }
    }

    &-button, a[role="button"] {
        display: inline-block;
        background: none;
        border: 1px solid #d6d6d6;
        border-radius: 4px;
        color: $gray-dark;
        font-size: 13px;
        line-height: 32px;
        padding: 0 4px;
        margin-right: $button-spacing;
        margin-bottom: $button-spacing;
        min-width: 32px;
        position: relative;
        text-decoration: none;
        text-align: center;
        user-select: none;

        &.current {
            background: $blue;
            border-color: $blue;
            color: #fff;
        }

        &-prev, &-next {
            &[disabled] {
                svg {
                    fill: #a1a1a1;
                }
            }
        }
    }
}

#invest-reasons {
    .section-header {
        font-size: 40px;
        margin: 62px 0;
        letter-spacing: .3px;
    }

    @include media-breakpoint-down(sm) {
        .section-header {
            font-size: 24px;
            margin: 48px 0;
            margin-bottom: 30px;
        }
    }
}

#landing-blocks {
    margin-top: 84px;
    margin-bottom: 80px;

    @include media-breakpoint-down(sm) {
        margin-top: 0;
        margin-bottom: 40px;
    }
}

#team {
    margin-top: 115px;

    @include media-breakpoint-down(md) {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        display: block;
        margin-top: 0;
    }
}

#contacts {
    margin-top: 62px;
    margin-bottom: 156px;

    h2 {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 44px;
        margin-top: 10px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 0;
        margin-bottom: 40px;

        h2 {
            font-size: 24px;
            margin-bottom: 26px;
        }

        p {
            font-size: 12px;
        }

        .landing-location-block-container .pr-4 {
            padding: 0 !important;
        }

        .contacts-map-wrapper {
            height: 210px;
        }
    }
}

#choose-plan {
    margin-bottom: 80px;

    @include media-breakpoint-down(sm) {
        margin-bottom: 0;
    }
}

.settings-container {
    max-width: 760px;
    margin: 0 auto;
    padding: 0 15px;
}

#faqs {
    color: #737B7D;

    h1, h2, h3, h4 {
        color: #373F41;
    }

    h2 {
        font-weight: 700;
    }
}

.MuiTimelineDot-root {
    background-color: #346EB5 !important;
    box-shadow: 0 0 8px 1px #ddecff !important;
}

#questions {
    background: rgba(60, 100, 177, 0.04);

    .card {
        background: inherit;
    }

    h2 {
        font-weight: 800;
    }
}

#sharing-deals-steps {
    .step-number {
        display: inline-block;
        width: 38px;
        line-height: 38px;
        text-align: center;
        border-radius: 50%;
        background: #346EB5;
        color: #fff;
        vertical-align: middle;
    }
}

.text-40-24 {
    font-size: 40px;

    @include media-breakpoint-down(md) {
        font-size: 24px;
    }
}

.text-30-24 {
    font-size: 30px;

    @include media-breakpoint-down(md) {
        font-size: 24px;
    }
}

.text-24-18 {
    font-size: 24px;
    font-family: 'Comfortaa', sans-serif;
    font-weight: 700;

    @include media-breakpoint-down(md) {
        font-size: 18px;
    }
}

.overview-timeline-container {
    * {
        font-family: 'Comfortaa', sans-serif;
    }

    h3 {
        font-weight: 700;
        font-size: 24px;
    }

}

.close-subscription-btn {
    background: none !important;
    border: none !important;
    padding: 0 !important;

    &:hover, &:focus {
        color: #dc3545;
        text-decoration: underline;
    }
}

@media (min-width: 1024px) {
    #choose-plan .swiper-pagination {
        display: none;
    }
}

.export-my-deals-btn {
    @include media-breakpoint-down(sm) {
        font-size: 12px;
        padding: 8px 16px;
    }
}

.table-sort-buttons {
    $arrow-size: 7px;
    $button-width: 11px;
    $button-height: 11px;

    position: relative;
    width: $button-width;
    height: 1em;
    transform: translateY(2px);

    button {
        border: none;
        background: none;
        width: $button-width;
        height: $button-height;
        padding: 0;
        margin: 2px 0;
        position: absolute;
    }

    .btn-desc {
        border-left: $arrow-size solid transparent;
        border-right: $arrow-size solid transparent;
        border-top: $arrow-size solid rgba(#fff, .5);
        top: 50%;
        left: 0;

        &:hover, &.active {
            border-top: $arrow-size solid rgba(#fff, 1);
        }
    }

    .btn-asc {
        border-left: $arrow-size solid transparent;
        border-right: $arrow-size solid transparent;
        border-bottom: $arrow-size solid rgba(#fff, .5);
        bottom: 50%;
        left: 0;

        &:hover, &.active {
            border-bottom: $arrow-size solid rgba(#fff, 1);
        }
    }
}

.dashboard-table thead td {
    white-space: nowrap;
}