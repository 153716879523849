$header-size: 80px;
$header-size-sm: 114px;
$sidebar-size: 82px;
$sidebar-active-size: $sidebar-size + 200px;
$padding-sm: 0;

.dashboard {
    &-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: $header-size;
        background: #fff;

        .navbar-nav .nav-link {
            color: #202020 !important;
            margin: 0 10px;

            &:hover, &:focus {
                text-decoration: underline;
            }
        }

        .toggle-sidebar-button {
            padding-left: 15px;
        }

        &-navbar {
            @include media-breakpoint-up(lg) {
                padding-right: 3rem;
            }
        }
    }

    &-page-wrapper {
        min-height: 100vh;
        padding-top: $header-size;
        background: #f3f6fa;
        overflow: hidden;

        &.sidebar-active {
            .dashboard-content-wrapper {
            padding-left: calc(2rem + #{$sidebar-active-size});
            }
        }
    }

    &-content-wrapper {
        flex: 1 1 auto;
        padding: 2rem;
        padding-left: calc(2rem + #{$sidebar-size});
        max-width: 1920px;
        transition: padding .3s;
    }

    &-sidebar-wrapper {
        padding-top: $header-size;
        box-shadow: 4px 0px 16px rgba(16, 30, 115, 0.08);
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 900;
        height: 100%;
    }
}

@include media-breakpoint-down(md) {
    .dashboard {
        &-content-wrapper {
            padding-left: $padding-sm !important;
            padding-right: $padding-sm !important;
        }

        &-sidebar-wrapper {
            width: 100%;
            max-width: 380px;
            transform: translateX(-100%);
            transition: transform .3s;
            padding-top: $header-size-sm;
        }

        &-page-wrapper {
            padding-top: $header-size-sm;
        }

        &-page-wrapper.sidebar-active &-sidebar-wrapper {
            transform: translateX(0);
        }

        &-header {
            height: 114px !important;
        }

        &-header-navbar {
            flex-wrap: wrap;
            font-size: 14px;

            .dashboard-header-split {
                flex: 0 0 100%;
            }

            .toggle-sidebar-button {
                order: 10;
                padding: 0 2px;
            }

            .navbar-nav {
                order: 11;

                .nav-link {
                    margin-left: 30px;
                    margin-right: 0;
                }
            }

            .navbar-brand {
                padding: 8px 0;
            }

            .nav-profile-link {
                padding-right: 4px;
            }

            .notifications-button {
                margin-left: auto;
            }
        }
    }
}